import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectList from '../components/ProjectList'; // Import du composant ProjectList
import styles from '../styles/pagesStyles/realiz.module.scss';

const Realiz = ({ data }) => {
  const { field } = useParams(); // Récupère le paramètre field depuis l'URL
  const [projects, setProjects] = useState(null); // État pour stocker les travaux ciblés

  useEffect(() => {
    // Réinitialise les projets avant de charger les nouveaux
    setProjects(null);

    // Vérifie si les données existent et met à jour les projets
    if (data && data.realiz && data.realiz[field]) {
      setProjects(data.realiz[field]);
    }
  }, [field, data]);

  useEffect(() => {
    // Fait défiler la page vers le haut à chaque changement de field
    window.scrollTo(0, 0);
  }, [field]); // Le défilement s'active à chaque changement de field

  return (
    <div className={styles.realizPage}>
      <h2>Projets - {field}</h2>
      <ProjectList projects={projects} />
    </div>
  );
};

export default Realiz;
