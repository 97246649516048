import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Realiz from './pages/Realiz';
import Contact from './pages/Contact';
import Confidentiality from './pages/Confidentiality';
import NotFound from './pages/404';
import Header from './components/Header';
import Footer from './components/Footer';
import { fetchData } from './utils/dataService';
import './index.scss';

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchData();
        console.log('Données récupérées:', result);
        setData(result);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    getData();

    // Précharge le GIF de chargement
    const preLoadGif = new Image();
    preLoadGif.src = 'https://i.giphy.com/Ii3seJlOgcXqdkU5dY.webp';
  }, []);

  return (
    <div id="app">
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/realiz/:field" element={<Realiz data={data} />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Confidentiality" element={<Confidentiality />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
