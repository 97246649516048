import emailjs from 'emailjs-com';

export const sendEmail = async (formData) => {
  const templateParams = {
    name: formData.name,
    email: formData.email, // Ceci est l'email de l'utilisateur
    message: formData.message,
    reply_to: formData.email, // Assurez-vous d'envoyer l'email dans le champ reply_to
  };

  try {
    await emailjs.send(
      'service_cll1hfr',
      'template_5gud0qa',
      templateParams,
      'jz9RJh13-7cMgO6o9'
    );

    return { success: true };
  } catch (error) {
    console.error("Échec de l'envoi de l'email:", error);
    return { success: false };
  }
};
