export async function fetchData() {
  try {
    const response = await fetch('/data.json');
    const data = await response.json(); // Conversion des données en JSON
    return data; // Retourne les données récupérées
  } catch (error) {
    console.error('Erreur lors de la récupération des données:', error);
    return null; // Retourne null en cas d'erreur
  }
}
