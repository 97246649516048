// 404.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/pagesStyles/NotFound.module.scss'; // Importer le fichier SCSS spécifique pour la page 404

const NotFound = () => {
  return (
    <div className={styles.notFoundPage}>
      <h1 className={styles.title}>404</h1>
      <p className={styles.message}>
        Oups ! La page que vous recherchez n'existe pas.
      </p>
      <Link to="/" className={styles.homeLink}>
        Retourner à l'accueil
      </Link>
    </div>
  );
};

export default NotFound;
