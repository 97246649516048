import React, { useState } from 'react';
import styles from '../styles/pagesStyles/Contact.module.scss';
import { sendEmail } from '../utils/emailService';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const emailServiceResponse = await sendEmail(formData);

      if (emailServiceResponse.success) {
        setSubmitted(true);
      } else {
        setError("L'envoi a échoué, veuillez réessayer.");
      }
    } catch (err) {
      setError("Une erreur s'est produite, veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.contactContainer}>
      <h1>Contact</h1>
      {submitted ? (
        <p>Merci pour votre message ! Nous reviendrons vers vous bientôt.</p>
      ) : (
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <label htmlFor="name">Prénom et nom *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Entrez votre prénom et votre nom"
          />

          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Entrez votre adresse de courriel"
          />

          <label htmlFor="message">Message *</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            placeholder="Entrez votre message"
          />

          {error && <p className={styles.errorMessage}>{error}</p>}

          <button
            type="submit"
            className={styles.submitButton}
            disabled={loading}
          >
            {loading ? 'Envoi en cours...' : 'Envoyer'}
          </button>
        </form>
      )}
    </div>
  );
};

export default Contact;
