import React, { useState } from 'react';
import Thumbnail from './Thumbnail'; // Assurez-vous d'avoir un composant Thumbnail pour l'affichage des vignettes
import Modal from './Modal'; // Assurez-vous d'importer le composant Modal
import styles from '../styles/componentsStyles/ProjectList.module.scss'; // Vous pouvez définir votre style ici

const ProjectList = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleThumbnailClick = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  if (!projects) {
    return <div className={styles.noProjects}>Aucun projet disponible</div>;
  }

  return (
    <div className={styles.projectList}>
      {Object.keys(projects).map((key) => (
        <Thumbnail
          key={key} // Clé unique basée sur l'identifiant du projet
          project={projects[key]}
          onClick={() => handleThumbnailClick(projects[key])}
        />
      ))}
      <Modal
        project={selectedProject}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default ProjectList;
