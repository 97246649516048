import React, { useState } from 'react';
import styles from '../styles/componentsStyles/Slider.module.scss';

const Slider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const openImageModal = () => {
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  if (images.length === 0) return null;

  return (
    <div className={styles.slider}>
      <div className={styles.sliderContent} onClick={openImageModal}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`${styles.image} ${index === currentIndex ? styles.active : styles.inactive}`}
          />
        ))}
      </div>
      {images.length > 1 && (
        <>
          <button className={styles.prev} onClick={handlePrev}>
            &#10094;
          </button>
          <button className={styles.next} onClick={handleNext}>
            &#10095;
          </button>
        </>
      )}

      {/* Modal pour l'image en grand */}
      {isImageModalOpen && (
        <div className={styles.imageModalOverlay} onClick={closeImageModal}>
          <div
            className={styles.imageModalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={closeImageModal}>
              &times;
            </button>
            <img
              src={images[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className={styles.imageModal}
            />
            <>
              <button className={styles.prev} onClick={handlePrev}>
                &#10094;
              </button>
              <button className={styles.next} onClick={handleNext}>
                &#10095;
              </button>
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;
