import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../styles/componentsStyles/Header.module.scss';

const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to determine if the current route matches
  const isActive = (path) => {
    return location.pathname === path ? styles.active : '';
  };

  return (
    <header className={styles.header}>
      <Link to="/" onClick={() => setIsMenuOpen(false)}>
        <img src="/img/logo.webp" alt="Header Logo" className={styles.logo} />
      </Link>
      <button className={styles.burger} onClick={handleMenuToggle}>
        <span className={styles.burgerBar}></span>
        <span className={styles.burgerBar}></span>
        <span className={styles.burgerBar}></span>
      </button>
      <nav className={`${styles.nav} ${isMenuOpen ? styles.navOpen : ''}`}>
        <ul className={styles.navList}>
          <li className={isActive('/')}>
            <Link to="/" onClick={() => setIsMenuOpen(false)}>
              Accueil
            </Link>
          </li>
          <li
            className={`${styles.dropdown} ${isActive('/realiz/3D_VFX') || isActive('/realiz/MotionDesign') || isActive('/realiz/CourtMetrages') || isActive('/realiz/Compositing') ? styles.active : ''}`}
            onClick={handleDropdownToggle}
          >
            <span>Réalisations</span>
            <ul
              className={`${styles.dropdownMenu} ${isDropdownVisible ? styles.dropdownMenuVisible : ''}`}
            >
              <li className={isActive('/realiz/3D VFX et Compositing')}>
                <Link
                  to="/realiz/3D VFX et Compositing"
                  onClick={() => setIsMenuOpen(false)}
                >
                  3D/VFX et Compositing
                </Link>
              </li>
              <li className={isActive('/realiz/MotionDesign')}>
                <Link
                  to="/realiz/MotionDesign"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Motion Design
                </Link>
              </li>
              <li className={isActive('/realiz/CourtMetrages')}>
                <Link
                  to="/realiz/CourtMetrages"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Court Métrage
                </Link>
              </li>
            </ul>
          </li>
          <li className={isActive('/contact')}>
            <Link to="/contact" onClick={() => setIsMenuOpen(false)}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
