import React, { useRef, useEffect } from 'react';
import styles from '../styles/pagesStyles/Home.module.scss';
import { Link } from 'react-router-dom';

const Home = (data) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    // Fixer la vidéo sur la dernière frame lorsqu'elle se termine
    const handleVideoEnd = () => {
      video.pause();
      video.currentTime = video.duration; // Figer sur la dernière frame
    };

    video.addEventListener('ended', handleVideoEnd);

    // Nettoyage
    return () => {
      video.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

  return (
    <div className={styles.homePage}>
      <div className={styles.videoContainer}>
        <video
          ref={videoRef}
          src="/video/yohandoens-banner.mp4"
          className={styles.video}
          autoPlay
          muted
          playsInline
        />
      </div>
      <div className={styles.sectionContainer}>
        <section className={styles.section}>
          <h2>3D/VFX et Compositing</h2>
          <div className={styles.content}>
            <p>
              Lors de ma formation en 3D/FX à ISART Digital, j'ai eu l'occasion
              de réaliser divers projets en utilisant des techniques telles que
              le modeling, le shading, le rigging, l'animation, le rendering et
              le compositing.
            </p>
            <div className={styles.visu}>
              <img
                className={styles.img}
                src="/img/realizImg/C1P6/basement-doens-yohan-combo-07.webp"
                alt="Laboratoire souterrain sombre avec des équipements industriels, des échantillons de roches, des caisses militaires et un ascenseur marqué '-2'."
              />
            </div>
          </div>
          <Link
            to="/realiz/3D VFX et Compositing"
            className={styles.linkButton}
          >
            Découvrir
          </Link>
        </section>
        <section className={styles.section}>
          <h2>Motion Design</h2>
          <div className={styles.content}>
            <div className={styles.visu}>
              <img
                className={styles.img}
                src="/img/gif/Hnet-image.webp"
                alt="couverture du court métrage Jurassicos"
              />
            </div>
            <p>
              Cette page regroupe différents projets en motion design, réalisés
              principalement avec Illustrator, Photoshop et After Effects. Ces
              travaux illustrent l'évolution de mes compétences techniques à
              travers divers styles et approches.
            </p>
          </div>
          <Link to="/realiz/MotionDesign" className={styles.linkButton}>
            Découvrir
          </Link>
        </section>
        <section className={styles.section}>
          <h2>Court métrage</h2>
          <div className={styles.content}>
            <p>
              À travers différentes expériences en audiovisuel, j'ai pu explorer
              des aspects tels que la réalisation de courts-métrages, la
              révision de scénarios et l'art du montage, avec pour objectif de
              donner vie à des récits visuels.
            </p>
            <div className={styles.visu}>
              <img
                className={styles.img}
                src="/img/gif/00a4567a-5ad4-4fcc-b13c-a9b9601849a5.webp"
                alt="*"
              />
            </div>
          </div>
          <Link to="/realiz/CourtMetrages" className={styles.linkButton}>
            Découvrir
          </Link>
        </section>
      </div>
    </div>
  );
};

export default Home;
