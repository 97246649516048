import React, { useState, useEffect } from 'react';
import styles from '../styles/componentsStyles/Thumbnail.module.scss';

const Thumbnail = ({ project, onClick }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false); // Réinitialise l'état de chargement à chaque changement de projet
    const img = new Image();
    img.src = project.thumbnail || project.img[0];
    img.onload = () => setLoaded(true); // Indique que l'image est chargée
  }, [project]); // Le hook se déclenche à chaque changement de projet

  return (
    <div className={styles.thumbnail} onClick={onClick}>
      {loaded ? (
        <img
          src={project.thumbnail || project.img[0]}
          alt={project.title}
          className={styles.media}
        />
      ) : (
        <img
          src="https://i.giphy.com/Ii3seJlOgcXqdkU5dY.webp"
          alt="Chargement..."
          className={styles.loadingGif}
        />
      )}
      <h3 className={styles.title}>{project.title}</h3>
    </div>
  );
};

export default Thumbnail;
