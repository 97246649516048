import React from 'react';
import Slider from './Slider';
import VideoSlider from './VideoSlider';
import styles from '../styles/componentsStyles/Modal.module.scss';

const Modal = ({ project, isOpen, onClose }) => {
  if (!isOpen || !project) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>

        {/* Titre */}
        <div className={styles.sectionTitle}>
          <h2 className={styles.title}>{project.title}</h2>
        </div>

        {/* Pitch */}
        {project.pitch && (
          <div className={styles.section}>
            <h4>Pitch:</h4>
            <p>{project.pitch}</p>
          </div>
        )}

        {/* Affichage des vidéos avec un slider si plus d'une vidéo */}
        {project.videos && project.videos.length > 0 && (
          <div className={styles.section}>
            {project.videos.length > 1 ? (
              <VideoSlider videos={project.videos} />
            ) : (
              <iframe
                className={styles.video}
                src={project.videos[0].url}
                title={project.videos[0].title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ width: '100%', height: '450px' }}
              />
            )}
          </div>
        )}

        {/* Affichage des images (slider) */}
        {project.img && project.img.length > 0 && (
          <div className={styles.section}>
            <Slider images={project.img} />
          </div>
        )}

        {/* Description */}
        <div className={styles.section}>
          <h4>Description :</h4>
          <p>{project.txt}</p>
        </div>

        {/* Logiciels utilisés - Afficher uniquement si le champ softwares n'est pas vide */}
        {project.softwares && project.softwares.length > 0 && (
          <div className={styles.section}>
            <h4>Softwares Used:</h4>
            <div className={styles.softwaresLogos}>
              {project.softwares.map((software, index) => (
                <img key={index} src={software} alt={`Software ${index}`} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
