import React from 'react';
import styles from '../styles/componentsStyles/VideoSlider.module.scss';

const VideoSlider = ({ videos }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const handlePrevClick = () => {
    setCurrentSlide(currentSlide === 0 ? videos.length - 1 : currentSlide - 1);
  };

  const handleNextClick = () => {
    setCurrentSlide(currentSlide === videos.length - 1 ? 0 : currentSlide + 1);
  };

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.videoSlide}>
        <iframe
          className={styles.video}
          src={videos[currentSlide].url}
          title={videos[currentSlide].title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      <div className={styles.controls}>
        <button className={styles.prevButton} onClick={handlePrevClick}>
          &#10094; Précédent
        </button>

        {/* Compteur vidéo */}
        <div className={styles.counter}>
          {currentSlide + 1}/{videos.length}
        </div>

        <button className={styles.nextButton} onClick={handleNextClick}>
          Suivant &#10095;
        </button>
      </div>
    </div>
  );
};

export default VideoSlider;
