import React from 'react';
import styles from '../styles/pagesStyles/Confidentiality.module.scss';

const Confidentiality = () => {
  return (
    <div className={styles.confidentialityPage}>
      <div className={styles.content}>
        <h1>Politique de confidentialité</h1>
        <h2>À propos</h2>
        <p>
          Vous êtes sur le site internet de Yohan Doens, étudiant en effets
          spéciaux Cinéma 3D.
        </p>
        <h2>Médias</h2>
        <p>
          Toutes les photos et vidéos ont été réalisées par Yohan ou en équipe.
          La ré-utilisation de ces médias est soumise à autorisation.
        </p>
        <h2>Cookies</h2>
        <p>
          Un cookie est un petit fichier stocké provisoirement pour suivre et
          améliorer votre navigation. Certains cookies permettent de vous
          identifier pour vous donner accès aux fonctionnalités de votre compte
          sur le site.
          <br />
          <br />
          Ce site internet n'héberge aucun compte utilisateur à l'exception du
          compte de l'administrateur du site.
        </p>
        <h2>Contenu embarqué depuis d’autres sites</h2>
        <p>
          Les articles de ce site peuvent inclure des contenus intégrés (par
          exemple des vidéos, images, articles…). Le contenu intégré depuis
          d’autres sites se comporte de la même manière que si le visiteur se
          rendait sur cet autre site.
          <br />
          <br />
          Ces sites web pourraient utiliser des cookies, embarquer des outils de
          suivis tiers, suivre vos interactions avec ces contenus embarqués si
          vous disposez d’un compte connecté sur leur site web.
        </p>
        <h2>Utilisation et transmission de vos données personnelles</h2>
        <p>
          Sur ce site, vos données personnelles sont reçues uniquement via le
          formulaire de contact lorsque vous le remplissez et l'envoyez. Ces
          informations sont envoyées par courriel à l'administrateur du site et
          immédiatement supprimées du site internet.
          <br />
          <br />
          Ce site ne conserve ni ne transmet à un tiers aucune donnée
          personnelle.
          <br />
          <br />
          Ce site ne récupère aucune adresse IP.
        </p>
      </div>
    </div>
  );
};

export default Confidentiality;
