import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/componentsStyles/Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.infoFooter}>
        {/* Section Infos Pratiques */}
        <div className={styles.infoPratiques}>
          <h3>INFOS PRATIQUES</h3>
          <ul>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/confidentiality">Confidentialité</Link>
            </li>
          </ul>
        </div>

        {/* Section Mes Formations */}
        <div className={styles.mesFormations}>
          <h3>MES FORMATIONS</h3>
          <ul>
            <li>
              <a
                href="https://www.isartdigital.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                ISART – L’école du jeu vidéo & de l’animation 3D-FX
              </a>
            </li>
            <li>
              <a
                href="https://www.mjm-design.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                MJM Graphic Design Bordeaux
              </a>
            </li>
          </ul>
        </div>

        {/* Section Mes Réseaux */}
        <div className={styles.mesReseaux}>
          <h3>SUIVEZ-MOI</h3>
          <div className={styles.socialIcons}>
            <a
              href="https://www.linkedin.com/in/yohan-doens-000832158/?originalSubdomain=fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/img/logoReseaux/pngimg.com - linkedIn_PNG2.webp"
                alt="LinkedIn"
                className={styles.icon}
              />
            </a>
            <a
              href="https://www.youtube.com/@doensproduction172"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/img/logoReseaux/pngimg.com - youtube_PNG12.webp"
                alt="YouTube"
                className={styles.icon}
              />
            </a>
            <a
              href="https://www.instagram.com/doens_production/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/img/logoReseaux/pngimg.com - instagram_PNG9.webp"
                alt="Instagram"
                className={styles.icon}
              />
            </a>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className={styles.copyr}>
        <p>© 2024 Yohan DOENS</p>
      </div>
    </footer>
  );
};

export default Footer;
